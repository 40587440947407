<template lang="pug">
  Card(:title="$t('security level')" dis-hover)
    //- div 图形
    //-   p 您当前的帐号安全程度：
    //-   p 安全级别：低
    //- h3 密码强度
    //- div 弱 中 强
    //- p 安全性高的密码可以使账户更安全。建议您定期更换密码。
    //- div 已设置 | 立即修改
    //- h3 手机绑定
    //- p 已绑定手机：180****2039
    //- div 已绑定 | 立即修改
    //- h3 邮箱绑定
    //- p 为了您的账户安全，请绑定邮箱。
    //- div 未绑定 | 立即绑定
    //- h3 个人MFA
    //- p 绑定个人 MFA 后，您可以在登录时进行二次验证，更有效的保障您的账户安全。
    //- div 未绑定 | 立即绑定
          
</template>

<script>
import moment from 'moment'
export default {
  name: 'SecurityView',
  components: {},
  data () {
    return {
      upload_api: `${this.$http.defaults.baseURL}attachment`,
      user: {
        profile: {},
        display_name: '',
        account: '',
        password: '',
        repassword: '',
        email: '',
        mobile: '',
        description: '',
        status: true
      },
      formAddRuleValidate: {},
      loading: false,
      model1: '',
      data: []
    }
  },
  computed: {
    all_names() {
      let names = []
      if (this.user.account) names.push(this.user.account)
      if (this.user.display_name) names.push(this.user.display_name)
      if (this.user.profile && this.user.profile.nicename) names.push(this.user.profile.nicename)
      if (this.user.profile && this.user.profile.realname) names.push(this.user.profile.realname)
      return [...new Set(names)]
    }
  },
  methods: {
    get_profile() {
      return this.$http.get('user/profile')
    },
    update_profile(data) {
      return this.$http.put('user/profile', data)
    },
    update_password(data) {
      return this.$http.put('user/password', data)
    },
    update_email(data) {
      return this.$http.put('user/email', data)
    },
    update_mobile(data) {
      return this.$http.put('user/mobile', data)
    },

    check_horoscope(val) {
      if (!val) return this.user.profile.horoscope = '';
      const horoscopes = [
        { label: '白羊座', date: [ 3.21, 4.19 ]},
        { label: '金牛座', date: [ 4.20, 5.20 ]},
        { label: '双子座', date: [ 5.21, 6.21 ]},
        { label: '巨蟹座', date: [ 6.22, 7.22 ]},
        { label: '狮子座', date: [ 7.23, 8.22 ]},
        { label: '处女座', date: [ 8.23, 9.22 ]},
        { label: '天秤座', date: [ 9.23, 10.23 ]},
        { label: '天蝎座', date: [ 10.24, 11.22 ]},
        { label: '射手座', date: [ 11.23, 12.21 ]},
        { label: '摩羯座', date: [ 12.22, 1.18 ]},
        { label: '水瓶座', date: [ 1.20, 2.18 ]},
        { label: '双鱼座', date: [ 2.19, 3.20 ]},
      ]
      let item = horoscopes.find(item => {
        let date_start = moment(`${moment(val).year()}.${item.date[0]} 00:00:00`, 'YYYY.MM.DD hh:mm:dd');
        let date_end = moment(`${moment(val).year()}.${item.date[1]} 23:59:59`, 'YYYY.MM.DD hh:mm:dd');
        // console.log(date_start, date_end, moment(val))
        return moment(val).isBetween(date_start, date_end)
      })
      this.user.profile.horoscope = item.label;
    },

    async submit() {
      try {
        this.loading = true

        const gender_setter = [ 'unknown', 'male', 'female' ]
        this.user.profile.gender = gender_setter.findIndex(item => item === this.user.profile.gender)
        await this.update_profile(this.user)

        this.loading = false
        this.$message.success('更新成功')
        
        const gender_getter = [ 'unknown', 'male', 'female' ];
        this.user.profile.gender = gender_getter.find((item, index) => index === this.user.profile.gender);
      } catch (err) {
        this.loading = false
        this.$message.error(err)
      }

      // 更新密码
      // this.update_password({
      //   orgPSW: '234',
      //   newPSW: 'vvb',
      //   repPSW: 'vvb'
      // })

      // 更改邮箱
      // this.update_email({
      //   email: '52953973@qq.com'
      // })

      // 更改手机
      // this.update_mobile({
      //   mobile: '13750452550',
      //   captcha: '123456'
      // })

      // this.user.gender = this.user.sex === 'unknown' ? 0 : this.user.sex === 'male' ? 1 : 2
      // this.$http.put(`user/profile`, this.user).then(() => {
      //   this.$message.success('更新成功')
      // }).catch(err => {
      //   this.$message.error(err.message)
      // })
    },
    // 提交表单
    handleSubmit (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.$http.put(`user/${this.user.id}`, this.user).then(res => {
            window.console.log(res)
            this.$message.success('修改成功!')
            this.$refs[name].resetFields()
            this.get_date()
          }).catch(err => {
            this.$message.info(err.message)
          })
        } else {
          this.$message.error('提交失败!')
        }
      })
    },
    // 重置表单
    handleReset (name) {
      this.$refs[name].resetFields()
    }
  },
  mounted() {}
}
</script>

<style lang="less" scoped>
</style>
